@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
:root {
  --black-gradient: linear-gradient(
    144.39deg,
    #ffffff -278.56%,
    #6d6d6d -78.47%,
    #11101d 91.61%
  );
  --card-shadow: 0px 20px 100px -10px rgba(66, 71, 91, 0.1);
}

* {
  scroll-behavior: smooth;
}
.navsmall:hover {
  transform: scale(1.05);
}
.text-gradient {
  background: radial-gradient(
    64.18% 64.18% at 71.16% 35.69%,
    #def9fa 0.89%,
    #bef3f5 17.23%,
    #9dedf0 42.04%,
    #7de7eb 55.12%,
    #5ce1e6 71.54%,
    #33bbcf 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.text-gradient {
  --tw-bg-opacity: 1;
    background-color: rgb(107 114 128 / var(--tw-bg-opacity));
  filter: blur(0.5px);
  background: radial-gradient(
    64.18% 64.18% at 71.16% 35.69%,
    #def9fa 0.89%,
    #bef3f5 17.23%,
    #9dedf0 42.04%,
    #7de7eb 55.12%,
    #5ce1e6 71.54%,
    #33bbcf 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.bg-blue-gradient {
  background: linear-gradient(
    157.81deg,
    #def9fa -43.27%,
    #bef3f5 -21.24%,
    #9dedf0 12.19%,
    #7de7eb 29.82%,
    #5ce1e6 51.94%,
    #33bbcf 90.29%
  );
}
.bg-blue-gradient2 {
  background: linear-gradient(
    157.81deg,
    #def9fa -43.27%,
    #bef3f5 -21.24%,
    #b4f4f7 12.19%,
    #a7fcff 29.82%,
    #97fcff 51.94%,
    #abeffa 90.29%
  );
}
.pink__gradient {
  background: linear-gradient(90deg, #f4c4f3 0%, #fc67fa 100%);
  -webkit-filter: blur(900px);
  filter: blur(900px);
  transform: translate3d(0, 0, 0);
}

.bg-pink__gradient {
  background: linear-gradient(90deg, #f4c4f3 0%, #ff1afb8f 100%);
 
}

.bg-black-gradient {
  background: linear-gradient(
    144.39deg,
    #ffffff -278.56%,
    #6d6d6d -78.47%,
    #11101d 91.61%
  );
}

.bg-black-gradient-2 {
  background: linear-gradient(
    -168.39deg,
    #ffffff -278.56%,
    #6d6d6d -78.47%,
    #11101d 91.61%
  );
}

.bg-gray-gradient {
  background: linear-gradient(
    153.47deg,
    rgba(255, 255, 255, 0) -341.94%,
    #14101d 95.11%
  );
}

.box-shadow {
  background: linear-gradient(125.17deg, #272727 0%, #11101d 100%);
}

.bg-bluemain-gradient {
  -webkit-filter: blur(123px);
  filter: blur(123px);
  transform: translate3d(0, 0, 0);
  -webkit-background-filter: blur(123px);
  background: rgb(0,246,255);
background: linear-gradient(346deg,
 rgba(0,246,255,1) 8%,
  rgba(9,9,121,1) 72%, 
rgba(0,3,13,0.8673844537815126) 94%);
}

.box-shadow {
  box-shadow: 0px 20px 100px -10px rgba(66, 71, 91, 0.1);
  -webkit-box-shadow:0px 20px 100px -10px rgba(66, 71, 91, 0.1);
}

.formtext{

  padding: 10px 0;
  font-size: 16px;
  margin-bottom: 30px;
  border: none;
  outline: none;
  background: transparent;
}
.formtextlg{
  padding: 10px 0;
  font-size: 16px;
  margin-bottom: 30px;
  border: none;
  outline: none;
  background: transparent;
  border-bottom: .5px solid #fff;
}

.formtext::placeholder { 
  color: #5ce1e6;
  opacity: .8; 
}
.formtext:hover{ 
  transform: scale(1.05);
}

.formtextlg::placeholder { 
  color: #5ce1e6;
  opacity: .8; 
}
.formtextlg:hover{ 
  transform: scale(1.05);
}
.formbuttonlg:hover {
  background: #03e9f4;
  color: rgb(0, 0, 0);
  transform: scale(1.05);
  border-radius: 5px;
  box-shadow: 0 0 5px #03e9f4,
              0 0 25px #03e9f4,
              0 0 50px #03e9f4,
              0 0 100px #03e9f4;
}

.textarea{
min-height: 8rem;
}

.sidebar {
  -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.feature-card:hover {
  background: var(--black-gradient);
  box-shadow: var(--card-shadow);
}

.feature-card2:hover {
  background: #def9fa;
  transform: scale(1.05);
  color: #33bbcf;
  /* box-shadow: var(--card-shadow); */
}

.feedback-container .feedback-card:last-child {
  margin-right: 0px;
}

.feedback-card {
  background: transparent;
}

.feedback-card:hover {
  background: var(--black-gradient);
}

.box-shadow {
  background: linear-gradient(180deg, rgba(188, 165, 255, 0) 0%, #214d76 100%);
  -webkit-filter: blur(123px);
  filter: blur(123px);
  transform: translate3d(0, 0, 0);
}
.box-blur {
  /* background: linear-gradient(180deg, rgba(188, 165, 255, 0) 0%, #214d76 100%); */
  background: transparent;
}

.white__gradient {
  background: rgba(255, 255, 255, 0.6);
  -webkit-filter: blur(750px);
  filter: blur(750px);
}
.white__gradient2 {
  -webkit-backdrop-filter: blur(50px);
  background: rgb(60,255,240);
  background: linear-gradient(352deg, rgba(60,255,240,1) 0%, rgba(0,0,0,1) 100%);
  rotate: 180deg;
  -webkit-filter: blur(50px);
  filter: blur(50px);
  transform: translate3d(0, 0, 0);
}

.login-boxx {
  background: rgba(0,0,0,.5);
  box-sizing: border-box;
  -webkit-box-shadow:0 15px 25px rgba(0,0,0,.6);
  box-shadow: 0 15px 25px rgba(0,0,0,.6);
  border-radius: 10px;
}
.login-boxx2 {
  background: rgba(0,0,0,.5);
  box-sizing: border-box;
  -webkit-box-shadow:0 15px 25px rgba(0,0,0,.6);
  box-shadow: 0 15px 25px rgba(0,0,0,.6);
  /* border-radius: 10px; */
  border-color: #03e9f4;

}
.login-boxx2:hover {
  transform: scale(1.05);
}
.popup {
  background: rgb(0,0,0);
  background: linear-gradient(352deg, rgba(0,0,0,1) 0%, rgba(255,255,255,1) 100%);
opacity: 0.8;
}

.error {
  color: #ff0000;
}
.dropdown {
  border: 0 !important;  /*Removes border*/
  -webkit-appearance: none;  /*Removes default chrome and safari style*/
  -moz-appearance: none; /* Removes Default Firefox style*/
  width: 100px; /*Width of select dropdown to give space for arrow image*/
  text-indent: 0.01px; /* Removes default arrow from firefox*/
  text-overflow: "";  /*Removes default arrow from firefox*/ /*My custom style for fonts*/
  outline: 0;
  border-radius: 15px;
  padding: 5px;
  box-shadow: inset 0 0 5px rgba(000,000,000, 0.5);
}
}